@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.bg_design {
  background-image: url(./constants/images/bg.svg);
  background-size: 100% 90%;
  background-position: center top;
}

.modal_scroll::-webkit-scrollbar {
  width: 3px;
}

.modal_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.021);
}

.modal_scroll::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.253);
  outline: 1px solid rgba(112, 128, 144, 0.068);
}

@media screen and (min-width: 600px) {
  /* Add your styles for larger screens here */
  .modal_bounce {
    animation: bounce 2s ease-in-out;
  }
  .bounce {
    animation: bounce 2s ease-in-out;
  }
}
@media screen and (max-width: 600px) {
  .bounce {
    display: none;
  }
}

.slideIn {
  animation: slideIn 2s ease-in-out;
}

@media screen and (min-width: 450px) {
  .slick-current img {
    opacity: 1;
    transform: scale(1.2);
  }
}
@media screen and (max-width: 450px) {
  .slick-current img {
    opacity: 1;
    transform: scale(1);
  }
}

.slick-slide img {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.slick-slide {
  transition: padding 0.2s ease-in-out;
}

.slick-list {
  overflow: visible;
}

.bar_content {
  display: block; /* Important to give the content a width */

  width: 100%;
  transform: translateX(100%); /* Animation start out of the screen */

  /* Add the animation */

  animation: move 40s linear infinite
    /* infinite make reapeat the animation indefinitely */;
}
@media (max-width: 500px) {
  .bar_content {
    /* Add the animation */
    animation: move 20s linear infinite
      /* infinite make reapeat the animation indefinitely */;
  }
  @keyframes move {
    to {
      transform: translateX(-180%);
    }
  }
}
/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-50%);
  }
}

@keyframes bounce {
  0% {
    transform: translate(-50%, 10%);
  }
  30% {
    transform: translate(-50%, 80%);
  }
  50%,
  80%,
  100% {
    transform: translate(-50%, 50%);
  }
  60% {
    transform: translate(-50%, 60%);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
